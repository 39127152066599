import axios from 'axios';

class Server {
  static url = "https://sconainitiative.kylehennig.com/";
  static token = "";

  static fetchAllPages() {
    return axios.get(this.url + "api/v1/pages/")
      .then(response => {
        const pages = response.data.data;
        return pages;
      }).catch(this.handleError);
  }

  static fetchSinglePage(pageNumber) {
    return axios.get(this.url + "api/v1/pages/" + pageNumber)
      .then(response => {
        const page = response.data.data;
        return page;
      }).catch(this.handleError);
  }

  static updatePage(pageNumber, title, content) {
    let config = { headers: { "Authorization": "Bearer " + this.token } };
    return axios.put(this.url + "api/v1/pages/" + pageNumber, { title: title, content: content }, config)
      .catch(this.handleError);
  }

  static sendMessage(message) {
    let config = { headers: { "Authorization": "Bearer " + this.token } };
    return axios.post(this.url + "api/v1/messages/", { message: message }, config)
      .catch(this.handleError);
  }

  static handleError(error) {
    if (window.confirm("Error connecting to server. Click OK for more details.")) {
      const errorMessage = error.stack ? error.stack : error.toString();
      alert(`${errorMessage}\n${JSON.stringify(error)}`);
    }
    console.log(error);
  }

  static logIn() {
    let username = window.prompt("Enter your username:", "");
    let password = window.prompt("Enter your password:", "");
    axios.post(this.url + "api/v1/login/", { username: username, password: password })
      .then(response => {
        window.alert("Success! You are now logged in. Please resubmit your changes.");
        this.token = response.data.token;
      })
      .catch(error => {
        window.alert("Incorrect username or password.");
      });
  }
}

export default Server;
