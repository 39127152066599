import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';

import './App.css';
import Header from './Header';
import Home from './pages/Home';
import Edit from './pages/Edit';
import Message from './pages/Message';
import LogOut from './pages/LogOut';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="app">
          <Header />
          <div className="content">
            <Route exact path="/" component={Home} />
            <Route path="/edit" component={Edit} />
            <Route path="/message" component={Message} />
            <Route path="/logout" component={LogOut} />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
