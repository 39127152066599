import React, { Component } from 'react';
import '../../node_modules/flatpickr/dist/flatpickr.min.css';
import Button from '../components/Button';
import Server from '../providers/server';
import './Message.css';

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      sendNow: true
    };
  }

  componentDidUpdate() {
    const flatpickr = require("flatpickr");
    flatpickr("#time", {
      minDate: "today",
      enableTime: true,
      altInput: true,
      altFormat: "M j, Y @ h:i K"
    });
  }

  messageChange = (event) => {
    this.setState({ message: event.target.value });
  }

  nowClicked = () => {
    this.setState(() => {
      return { sendNow: true };
    });
  }

  laterClicked = () => {
    this.setState(() => {
      return { sendNow: false };
    });
  }

  save = (event) => {
    if (Server.token.length === 0) {
      window.alert("You must log in before making any changes.")
      Server.logIn();
    } else {
      if (window.confirm("Are you sure you want to send this message?")) {
        Server.sendMessage(this.state.message)
          .then(response => {
            alert("Message sent!");
          });
      }
    }
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <h1>Message</h1>
        <form id="messageForm">
          <label className="label-above" htmlFor="message">Your message:</label>
          <textarea id="message" name="message" rows="10" cols="60" value={this.state.message} onChange={this.messageChange} />

          <p>Send this message:</p>
          <span className="radio-group">
            <input type="radio" id="sendNow" name="send" value="now" onClick={this.nowClicked} defaultChecked />
            <label htmlFor="sendNow">Now</label>
          </span>
          {false &&
            <span className="radio-group">
              <input type="radio" id="sendLater" name="send" value="later" onClick={this.laterClicked} />
              <label htmlFor="sendLater">Later</label>
            </span>
          }

          {!this.state.sendNow &&
            <div className="date-picker">
              <label className="label-above" htmlFor="time">At this time:</label>
              <input id="time" name="time" type="text" placeholder="Select date..." />
            </div>
          }

          <Button onClick={this.save} />
        </form>
      </div>
    );
  }
}

export default Message;
