import React, { Component } from 'react';
import {
  Route,
  Switch
} from 'react-router-dom';
import CKEditor from 'react-ckeditor-component';

import './Edit.css';
import Tile from '../components/Tile';
import Button from '../components/Button';
import Server from '../providers/server'

class Edit extends Component {
  render() {
    return (
      <div>
        <h1>Edit</h1>
        <Switch>
          <Route exact path="/edit" component={AllPages} />
          <Route path="/edit/:number" component={PageEditor} />
        </Switch>
      </div>
    );
  }
}

export default Edit;

class AllPages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pages: []
    }
  }

  componentDidMount() {
    Server.fetchAllPages()
      .then(pages => {
        this.setState({ pages: pages })
      });
  }

  render() {
    let tiles = this.state.pages.map((page) => {
      let date = new Date(page.lastUpdated * 1000).toDateString();
      return <Tile id={page.id} name={page.title} updated={date} key={page.id}></Tile>;
    });
    return (
      <div className="grid">
        <div>
          <Tile name="Page Name" updated="Last Updated" header></Tile>
          {tiles}
        </div>
      </div>
    );
  }
}

class PageEditor extends Component {
  constructor(props) {
    super(props);
    this.pageNumber = parseInt(props.match.params.number, 10);
    this.state = {
      title: "",
      content: "",
      doneLoading: false
    }
  }

  componentDidMount() {
    Server.fetchSinglePage(this.pageNumber)
      .then(page => {
        this.setState({
          title: page.title,
          content: page.content,
          doneLoading: true
        });
      });
  }

  updateContent = (newContent) => {
    this.setState({
      content: newContent
    });
  }

  onChange = (e) => {
    var newContent = e.editor.getData();
    this.setState({
      content: newContent
    });
  }

  onBlur = (e) => { }

  afterPaste = (e) => { }

  save = () => {
    if (Server.token.length === 0) {
      window.alert("You must log in before making any changes.")
      Server.logIn();
    } else {
      Server.updatePage(this.pageNumber, this.state.title, this.state.content)
        .then(response => {
          alert("Saved successfully!");
        });
    }
  }

  render() {
    if (!this.state.doneLoading) {
      return (<div>
        <p>"Loading..."</p>
      </div>);
    }

    return (
      <div>
        <p>{this.state.title}</p>
        <CKEditor
          id="contents"
          activeClass="page-contents"
          content={this.state.content}
          events={{
            "blur": this.onBlur,
            "afterPaste": this.afterPaste,
            "change": this.onChange
          }}
          config={{ height: "300px" }}
        />
        <Button onClick={this.save} />
      </div>
    );
  }
}
