import React, { Component } from 'react';

class LogOut extends Component {
  render() {
    return (
      <div>
        <h1>Log Out</h1>
        <p>Are you sure you want to log out?</p>
        <button className="button-yes">Yes</button>
        <button className="button-no">No</button>
      </div>
    );
  }
}

export default LogOut;
