import React, { Component } from 'react';
import './Button.css';

class Button extends Component {
  render() {
    return <input type="submit" className="button button-green" onClick={this.props.onClick} />;
  }
}

export default Button;
