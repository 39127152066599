import React, { Component } from 'react';
import './Tile.css';
import {
  Link
} from 'react-router-dom';

class Tile extends Component {
  render() {
    if (this.props.header) {
      return (
        <div className="tile tile-header">
          <span>{this.props.name}</span>
          <span>{this.props.updated}</span>
        </div>
      );
    } else {
      return (
        <Link to={"/edit/" + this.props.id} className="tile">
          <span>{this.props.name}</span>
          <span>{this.props.updated}</span>
        </Link>
      );
    }
  }
}

export default Tile;
