import React, { Component } from 'react';
import './Home.css';

class Home extends Component {
  render() {
    return (
      <div>
        <h1>Home</h1>
        <img className="phone" src={require("../assets/iPhone.svg")} alt="iPhone" />
      </div>
    );
  }
}

export default Home;
